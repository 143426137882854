export const LOAD_DATA_REQUEST = 'LOAD_DATA_REQUEST';
export const LOAD_DATA_SUCCESS = 'LOAD_DATA_SUCCESS';
export const LOAD_DATA_FAILURE = 'LOAD_DATA_FAILURE';

export const loadDataRequest = () => ({
  type: LOAD_DATA_REQUEST,
});

export const loadDataSuccess = (data) => ({
  type: LOAD_DATA_SUCCESS,
  payload: data,
});

export const loadDataFailure = (error) => ({
  type: LOAD_DATA_FAILURE,
  payload: error,
});
