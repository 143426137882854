import React from 'react';

export function FormError({ message }) {
  if (!message) return null;

  return (
    <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg border border-red-300" role="alert">
      {message}
    </div>
  );
}
