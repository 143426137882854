import axios from 'axios';

export const editDetails = async(details, docid) => {
    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/edit/details`, { details, docid });
    } catch (error) {
        console.error('Edit details failed:', error.message);
    }
};

export const editMonth = async(activeMonth, month, docid) => {
    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/editMonth`, { activeMonth, month, docid });
    } catch (error) {
        console.error('Edit month failed:', error.message);
    }
};