import React, { useState, useEffect, useCallback } from "react";
import Edit from "../assets/edit.svg";
import Search from "../assets/search.svg";
import "leaflet/dist/leaflet.css";
import DetailsModal from "../components/DetailsModal";
import MonthModal from "../components/MonthModal";
import Map from "../components/Map";
import { GrFormNext } from "react-icons/gr";
import { GrFormPrevious } from "react-icons/gr";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setUser } from "../redux/auth/authslice";
import { loadDataRequest, loadDataSuccess, loadDataFailure } from "../redux/actions/actions";

import axios from "axios";

import { getCurrentYear, searchQueryFunction } from "../utils/utils";

import { editDetails, editMonth } from "../redux/edit/edit";

const Customer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const letters = ["A", "B", "C"];
  const years = ["2024", "2023", "2022", "2021"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [customers, setCustomers] = useState([]);

  // Search and Filter State
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(customers);
  const [activeLetter, setActiveLetter] = useState("");
  const [activeYear, setActiveYear] = useState("");
  const [activeMonth, setActiveMonth] = useState("");
  const [selectedRowId, setSelectedRowId] = useState("1");
  const [details, setDetails] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  const [lastVisibleDocId, setLastVisibleDocId] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const [pageNumber, setPageNumber] = useState(1);

  const itemsPerPage = 5; // Adjust to desired items per page

  useEffect(() => {
    dispatch(loadDataRequest());
    const loadData = async () => {
      try {
        const response = await axios.get(
          (lastVisibleDocId === null) ? `${process.env.REACT_APP_API_URL}/api/firebase/data?itemsPerPage=${itemsPerPage}` :
            `${process.env.REACT_APP_API_URL}/api/firebase/data?itemsPerPage=${itemsPerPage}&lastVisibleDocId=${lastVisibleDocId}`
        );
        setCustomers((prevCustomers) => [
          ...prevCustomers,
          ...response.data.customers
        ]);
        setFilteredData((prevFilteredData) => [
          ...response.data.customers
        ]);

        // setSelectedRowId(response.data.customers[0].id);

        setIsLoading(false);

        dispatch(loadDataSuccess(response.data));
        // setLastVisibleDocId(response.data.lastVisibleDocId);
      } catch (error) {
        dispatch(loadDataFailure(error.message));
        setIsLoading(false);
      }
    };
    loadData();
  }, [dispatch, lastVisibleDocId]);


  const handleRowClick = (event) => {
    const trElement = event.currentTarget.closest('tr');
    const id = trElement.id;

    setSelectedId(id);
    setSelectedRowId(id);

    const selectedItem = customers.find((item) => item.id === id);

    if (selectedItem) {
      const detailsArray = {
        Magaca: selectedItem.magaca,
        Qaybta: selectedItem.qaybta,
        "Guri No": selectedItem.gurino,
        Telephone: selectedItem.telephone,
        Rate: selectedItem.rate,
        Discount: selectedItem.discount,
      };
      setDetails(detailsArray);
      handleMonthClick(activeMonth);
    }
  };

  const handleMonthClick = useCallback((month) => {
    const selectedItem = customers.find((item) => item.id === selectedId) || {};

    if (selectedId && month !== "Select a month") {
      const currentYear = activeYear || getCurrentYear();

      // Ensure the structure exists before trying to access it
      const biyaha = selectedItem.biyaha?.[currentYear]?.[month] || 0;
      const lacaq = selectedItem.lacaqqabasho?.[currentYear]?.[month] || 0;
      const invoice = biyaha * selectedItem.rate;

      const monthDataArray = {
        "Water Consumed": biyaha,
        Invoice: invoice.toString(),
        Receipt: lacaq.toString(),
        Balance: (invoice - lacaq).toString(),
      };

      setMonthData(monthDataArray);
    }
  }, [customers, selectedId, activeYear]);

  useEffect(() => {
    if (selectedId) {
      const selectedItem = customers.find((item) => item.id === selectedId);

      if (selectedItem) {
        const detailsArray = {
          Magaca: selectedItem.magaca,
          Qaybta: selectedItem.qaybta,
          "Guri No": selectedItem.gurino,
          Telephone: selectedItem.telephone,
          Rate: selectedItem.rate,
          Discount: selectedItem.discount,
        };
        setDetails(detailsArray);
        handleMonthClick(activeMonth);
      }
    }
  }, [selectedId, selectedRowId, activeMonth, customers, handleMonthClick]);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    filterData(query, activeLetter, activeYear, activeMonth);
  };

  const goPrevPage = () => {
    setPageNumber((prev) => prev - 1);
    const index = pageNumber * itemsPerPage ? pageNumber * itemsPerPage : 1;
    setLastVisibleDocId(customers[index].id);
  }

  const goNextPage = () => {
    setPageNumber((prev) => prev + 1);
    setLastVisibleDocId(customers[customers.length - 1].id);
  }

  const handleSearchQuery = async (query) => {
    try {
      const result = await searchQueryFunction(query);
      return result;
    } catch (error) {
      console.error("Error fetching search data:", error);
      return null;
    }
  };
  
  const filterData = async (query, letter, year, month) => {
    let filtered = customers;
  
    if (query) {
      filtered = filtered.filter((item) => {
        const magaca = item.magaca ? item.magaca.toLowerCase() : '';
        const telephone = item.telephone ? item.telephone.toString() : '';
        const gurino = item.gurino ? item.gurino.toString() : '';
        
        return (
          magaca.includes(query.toLowerCase()) ||
          telephone.includes(query.toLowerCase()) ||
          gurino.includes(query.toLowerCase())
        );
      });
  
      if (filtered.length === 0) {
        if (!isNaN(query)) { // Check if query is a number
          try {
            const searchQueryResult = await handleSearchQuery(query);
            if (searchQueryResult) {
              filtered = [searchQueryResult];
              console.log(customers);
              setCustomers((prevCustomers) => [...prevCustomers, searchQueryResult]);
              console.log(customers);
              
              setFilteredData(() => [searchQueryResult]);
            }
            // Optionally handle other states here if needed
            // setSelectedRowId("");
            // setDetails({});
            // setMonthData({});
          } catch (error) {
            console.error("Error handling search query:", error);
          }
        }
      }
    }
  
    if (letter) {
      filtered = filtered.filter((item) => item.qaybta === letter);
    }
    if (year) {
      // filtered = filtered.filter((item) => item.lacaqqabasho.key === year);
    }
    // if (month) {
    //   filtered = filtered.filter((item) => item.month === month);
    // }
  
    setFilteredData(filtered);
  };
  

  const handleFilterClick = (type, value) => {
    if (type === "letter") {
      const newLetter = activeLetter === value ? "" : value;
      setActiveLetter(newLetter);
      // filterData(searchQuery, newLetter, activeYear, activeMonth);
    } else if (type === "year") {
      const newYear = activeYear === value ? "" : value;
      setActiveYear(newYear);
      // filterData(searchQuery, activeLetter, newYear, activeMonth);
    } else if (type === "month") {
      const newMonth = activeMonth === value ? "Select a month" : value;
      setActiveMonth(newMonth);
      // filterData(searchQuery, activeLetter, activeYear, newMonth);
      handleMonthClick(value);
    }
  };

  // Modal
  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false);
  const [isMonthModalOpen, setMonthModalOpen] = useState(false);

  const toggleDetailsModal = () => setDetailsModalOpen(!isDetailsModalOpen);
  const toggleMonthModal = () => setMonthModalOpen(!isMonthModalOpen);

  const handleSaveDetails = (updatedDetails) => {
    setDetails(updatedDetails);
    editDetails(updatedDetails, selectedRowId);
  };

  const handleSaveMonthData = (updatedMonthData) => {
    setMonthData(updatedMonthData);
    editMonth(activeMonth, updatedMonthData, selectedRowId);
  };

  // Paginated Table
  const paginatedData = filteredData;

  // useEffect(() => {
  //   if (currentPage > Math.ceil(filteredData.length / itemsPerPage)) {
  //     setCurrentPage(1);
  //   }
  // }, [filteredData, currentPage, itemsPerPage]);

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, user]);

  if (isLoading && customers.length === 0) {
    return <div>Loading...</div>; // Show a loading indicator while data is being fetched
  }

  if (!user) {
    return null;
  }
  return (
    <div className="customer-main flex items-start gap-5 w-full lg:h-full sm:px-0">
      <div className="tables-and-map md:h-full max-w-[1195px] w-full mx-auto">
        <div className="flex-1 flex justify-start gap-7 xl:flex-row flex-col ">
          <div className=" bg-white shadow-custom flex-1  rounded-[24px] p-3 py-4 sm:p-5  min-w-[300px]">
            <div className="flex items-center justify-between gap-3">
              <h2 className="text-[#121212] md:text-[20px] text-[18px]  md:leading-[28px] leading-[20px] font-ibmplexsans font-semibold">
                Names
              </h2>
              <div className="bg-[#F4EDF8] rounded-[100px] h-[38px] px-5 flex items-center gap-4">
                <span className="md:w-[24px] w-[16px] md:h-[24px] h-[16px]">
                  <img src={Search} alt="Search" />
                </span>
                <input
                  type="text"
                  placeholder="Find Names"
                  className="bg-transparent w-full outline-none text-[14px] leading-[18px] font-ibmplexsans font-normal"
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>
            </div>

            {/* Table */}
            <div className="mt-5 w-full table-container h-[339px] overflow-hidden pr-2 md:pr-3 ">
              <table className="w-full">
                <tbody className="divide-gray-200 flex flex-col gap-5">
                  {paginatedData.map((item) => (
                    <tr
                      id={item.id}
                      // key={item.id}
                      onClick={(event) => handleRowClick(event)}
                      className={`flex items-center justify-between gap-5 rounded-[10px] h-[51px] cursor-pointer ${selectedRowId === item.id
                        ? "bg-[#6100A2] text-white"
                        : "bg-[#EDE9FE] text-[#49454F]"
                        } hover:bg-[#6100A2] hover:text-white`}
                    >
                      <td className="px-3 md:px-2 whitespace-nowrap md:text-[16px] text-[14px] md:leading-[16px] font-normal">
                        {item.magaca}
                      </td>
                      <td className="px-3 md:px-1 whitespace-nowrap md:text-[16px] text-[14px] md:leading-[16px] font-normal">
                        {item.telephone}
                      </td>
                      {/* <td className="px-3 md:px-2 whitespace-nowrap md:text-[16px] text-[14px] md:leading-[16px] font-normal">
                        {item.qaybta}
                      </td> */}
                      <td className="px-3 md:px-20 whitespace-nowrap md:text-[16px] text-[14px] md:leading-[16px] font-normal">
                        {item.gurino}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Pagination Controls */}
            <div className="flex justify-between mt-4">
              <button
                // onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                onClick={() => goPrevPage()}
                disabled={(pageNumber * itemsPerPage) < 5}
                className="px-4 py-2 rounded "
              >
                <GrFormPrevious />
              </button>
              {/* <span className="px-4 py-2">
                Page {currentPage} of {Math.ceil(filteredData.length / itemsPerPage)}
              </span> */}
              <button
                // onClick={() => setCurrentPage((prev) => prev + 1)}
                onClick={() => goNextPage()}
                // disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}
                className="px-4 rounded "
              >
                <GrFormNext />
              </button>
            </div>
          </div>


          {/* Details & Month */}
          <div className="flex-1 flex-wrap flex flex-col gap-4 w-full">
            {/* Details */}
            <div>
              <h3 className="text-[#121212] md:text-[20px] text-[18px] md:leading-[28px] leading-[25px] font-ibmplexsans font-semibold mb-3">
                Details
              </h3>
              <div className="bg-white rounded-[20px] px-4 sm:px-[30px] py-5">
                <div className="flex items-end justify-end">
                  <div
                    onClick={toggleDetailsModal}
                    className="flex w-[100px] cursor-pointer relative z-10 flex-wrap items-center justify-end gap-3"
                  >
                    <h4 className="text-[#121212] text-[14px] font-medium font-ibmplexsans leading-[18.2px]">
                      Edit
                    </h4>
                    <button>
                      <img src={Edit} alt="Edit" width={15} height={15} />
                    </button>
                  </div>
                </div>
                <div className="mt-[-14px] flex flex-col gap-5">
                  {Object.keys(details).map((key, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <h2 className="text-[#202020] md:text-[17px] sm:text-[15px] text-[16px] md:leading-[19px] sm:leading-[16px] leading-[14px] font-medium">
                        {key}:
                      </h2>
                      <h4 className="text-[#202020] md:text-[17px] sm:text-[15px] text-[16px] md:leading-[19px] sm:leading-[16px] leading-[14px] text-nowrap font-normal">
                        {details[key]}
                      </h4>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Month */}
            <div className="bg-white rounded-[20px] sm:w-auto w-full px-4 sm:px-[20px] py-5">
              <div className="flex items-center w-full justify-end gap-3">
                <h4 className="text-[#121212] text-[14px] font-medium font-ibmplexsans leading-[18.2px]">
                  Edit
                </h4>
                <button onClick={toggleMonthModal}>
                  <img src={Edit} alt="Edit" width={15} height={15} />
                </button>
              </div>
              <h2 className="text-[#121212] md:text-[20px] text-[18px] md:leading-[26px] leading-[20px] font-semibold font-ibmplexsans mt-[-14px]">
                Month: {activeMonth ? activeMonth : "Select a month"}
              </h2>
              <div className="mt-3.5 grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-4 gap-3">
                {(activeMonth !== "Select a month") &&
                  Object.keys(monthData).map((key, index) => (
                    <div
                      key={index}
                      className="bg-[#65558F1A] md:h-[63px] h-fit pt-0.5 rounded-xl flex items-center justify-center flex-col text-center px-[10px]"
                    >
                      <h3 className="text-[#65558F] md:text-[14px] text-[12px] font-semibold">
                        {key}
                      </h3>
                      <h2 className="text-[#262626] md:text-[16px] text-[15px] md:leading-[30px] leading-[25px] font-semibold">
                        {monthData[key]}
                      </h2>
                    </div>
                  ))}
              </div>
            </div>

            {isDetailsModalOpen && (
              <DetailsModal
                onClose={toggleDetailsModal}
                details={details}
                onSave={handleSaveDetails}
              />
            )}

            {isMonthModalOpen && (
              <MonthModal
                onClose={toggleMonthModal}
                monthData={monthData}
                onSave={handleSaveMonthData}
              />
            )}
          </div>
        </div>

        <Map />
      </div>

      {/* Filter By Letter */}
      <div className="filter-container bg-white w-full lg:max-w-[338px] xl:h-full max-w-full rounded-[24px] md:h-fit p-5">
        <h2 className="text-[#121212] md:text-[20px] text-[18px] md:leading-[28px] font-semibold font-ibmplexsans pb-5">
          Filter By Letter
        </h2>
        <div className="flex flex-wrap item gap-3">
          {letters.map((letter, index) => (
            <div
              key={index}
              className={`md:w-[86px] w-[48px] h-[48px] ${letter === activeLetter
                ? "bg-[#6100A2] text-white"
                : "bg-[#65558F1A] text-[#262626]"
                } rounded-xl text-[16px] leading-[30px] font-semibold flex items-center justify-center cursor-pointer`}
              onClick={() => handleFilterClick("letter", letter)}
            >
              {letter}
            </div>
          ))}
        </div>

        {/* Filter By Year */}
        <h2 className="text-[#121212] md:text-[20px] text-[18px] md:leading-[28px] font-semibold font-ibmplexsans pb-5 pt-8">
          Filter By Year
        </h2>
        <div className="flex flex-wrap item gap-3 flex-wrap">
          {years.map((year, index) => (
            <div
              key={index}
              className={`md:w-[86px] w-[70px] h-[48px] ${year === activeYear
                ? "bg-[#6100A2] text-white"
                : "bg-[#65558F1A] text-[#262626]"
                } rounded-xl text-[16px] leading-[30px] font-semibold flex items-center justify-center cursor-pointer`}
              onClick={() => handleFilterClick("year", year)}
            >
              {year}
            </div>
          ))}
        </div>

        {/* Filter By Month */}
        <h2 className="text-[#121212] md:text-[22px] text-[18px] md:leading-[28.6px] font-semibold font-ibmplexsans pb-5 py-8">
          Filter By Month
        </h2>
        <div className="flex item gap-3 flex-wrap">
          {months.map((month, index) => (
            <div
              key={index}
              className={`w-[90px] h-[48px] ${month === activeMonth
                ? "bg-[#6100A2] text-white"
                : "bg-[#65558F1A] text-[#262626]"
                } rounded-xl text-[13px] leading-[30px] font-semibold flex items-center justify-center cursor-pointer`}
              onClick={() => handleFilterClick("month", month)}
            >
              {month}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Customer;
