import React from "react";
import Layout from "./components/Layout";
import { useSelector } from "react-redux";
import Routers from "./router/Routes";
import "./App.css";

const App = () => {
  const { user } = useSelector((state) => state.auth);
  return (
    <>
      {user ? (
        <>
          <Layout>
            <Routers />
          </Layout>
        </>
      ) : (
        <>
          <Routers />
        </>
      )}
    </>
  );
};

export default App;
