import axios from "axios";

export const getCurrentYear = () => {
    return new Date().getFullYear();
};

export const searchQueryFunction = async (query) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/firebase/query?gurino=${query}`);
        return response.data;
    } catch (error) {
        console.error('Logout failed:', error.message);
    }
}

export const getUserDetails = async (email) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/firebase/user?email=${email}`);
        return response.data;
    } catch (error) {
        console.error('Get user details failed:', error.message);
    }
}