import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    error: null,
    loading: false
  },
  reducers: {
    loginStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.user = action.payload;
      state.loading = false;
    },
    loginFailure: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    logout: (state) => {
      state.user = null;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    }
  }
});

export const { loginStart, loginSuccess, loginFailure, logout, setUser } = authSlice.actions;

export const login = (email, password, navigate) => async (dispatch) => {
  dispatch(loginStart());
  try {
    const userCredential = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, { email, password });

    dispatch(loginSuccess(userCredential.data.user));
    navigate('/dashboard');
  } catch (error) {
    dispatch(loginFailure(error.message));
    navigate('/login?error=true');
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/logout`);
    dispatch(logout());
  } catch (error) {
    console.error('Logout failed:', error.message);
  }
};

export const authReducer = authSlice.reducer;
