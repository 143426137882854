import { useState } from 'react';
import loginFields from './loginFields';
import Input from "./Input";
import FormExtra from './extraForm';
import FormAction from './formAction';
import { FormError} from './errorForm';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/auth/authslice';
import { useNavigate, useSearchParams } from 'react-router-dom';

const fields = loginFields;
let fieldsState = {};
fields.forEach(field => fieldsState[field.id] = '');

export default function LoginComponent() {
    const [loginState, setLoginState] = useState(fieldsState);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const error = searchParams.get('error');
    const handleChange = (e) => {
        setLoginState({ ...loginState, [e.target.id]: e.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        
        dispatch(login(loginState["email-address"], loginState["password"], navigate));
      };

    return (
        <form className="mt-8 space-y-6">
            { error && <FormError message="Invalid email or password" /> }
            <div className="-space-y-px">
                {
                    fields.map(field =>
                        <Input
                            key={field.id}
                            handleChange={handleChange}
                            value={loginState[field.id]}
                            labelText={field.labelText}
                            labelFor={field.labelFor}
                            id={field.id}
                            name={field.name}
                            type={field.type}
                            isRequired={field.isRequired}
                            placeholder={field.placeholder}
                        />
                    )
                }
            </div>

            <FormExtra />
            <FormAction handleSubmit={handleSubmit} text="Login"/>

        </form>
    )
}