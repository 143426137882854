import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // uses localStorage by default
import { authReducer } from '../redux/auth/authslice';

// Configuration for redux-persist
const persistConfig = {
  key: 'root',
  storage,
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, authReducer);

// Create Redux store with the persisted reducer
export const store = configureStore({
  reducer: {
    auth: persistedReducer,
  },
});

// Create a persistor
export const persistor = persistStore(store);
