import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser, logout } from "../redux/auth/authslice"; // Make sure to import the logout action
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
    } else {
      navigate("/login");
    }

  }, [dispatch, navigate, user]);

  if (!user) {
    return null;
  }

  return (
    <div className="App">
      <h1>Welcome, {user.email}</h1>
      <button onClick={() => dispatch(logout())}>Logout</button>
    </div>
  );
};

export default Dashboard;
