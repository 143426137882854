export default function Header({
    heading,
    paragraph,
    subparagraph
}){
    return(
        <div className="mb-10">
            <div className="flex justify-center">
                <h1 className="text-4xl font-extrabold">Ceelcadde</h1>
            </div>
            <div className="flex justify-center">
                <h2 className="text-xl font-semibold">Water Supply</h2>
            </div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                {heading}
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600 mt-5">
            {paragraph} {' '}
            </p>
            <p className="mt-2 text-center text-sm text-gray-600">
            {subparagraph}
            </p>
        </div>
    )
}